import React, { useState, useEffect, useContext } from 'react'

import AllData from './CategoryList/AllData';
import EnglishPoems from './CategoryList/EnglishPoems';
import EnglishStories from './CategoryList/EnglishStoies';
import QuoteData from './CategoryList/QuoteData';
import TamilKathai from './CategoryList/TamilKathai';
import TamilPoems from './CategoryList/TamilPoems';
import Technical from './CategoryList/Technical';




const Home = () => {

    return (
        <>
        <AllData />
        <hr />
        <TamilPoems />
        <hr />
        <TamilKathai />
        <hr />
        <EnglishPoems />
        <hr />
        <EnglishStories />
        <hr />
        <Technical />
        <hr />
        <QuoteData />
        </>
    )
}

export default Home

















