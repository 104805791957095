import React from 'react';

// import {Link, Navigate} from 'react-router-dom';



// import '../App.css';


class Login extends React.Component {
    constructor() {
        super()
        this.state = {
            username:'',
            password:''
        };
        this.handleusername = this.handleusername.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.dismissError = this.dismissError.bind(this);
    }
    handleusername(evt) {
        this.setState({
          username: evt.target.value,
        });
      };
    
      handlePassword(evt) {
        this.setState({
          password:evt.target.value,
        });
      };

      dismissError() {
        this.setState({ error: '' });
      }

      handleSubmit(evt) {
          evt.preventDefault();
          const {username} = this.state
          const {password} = this.state


        //   localStorage.setItem('Login username', username);
        //   localStorage.setItem('Login Password', password);
          if (username !== "ajay682000" || password !== "%05AkSrvCitVirAk%05") { 
            return (
                this.setState({error: 'Account not found or \n Invalid username or password'})
                // alert("Invalid Credentials"),
                
                // window.location.replace('/')
                );
          } else{
              return(
                window.location.replace('/home')
                
                // <Navigate to="/#/hbd0" />
                // this.props.history.push('/#/hbd0')
                
              )
          }
          
        //   
      }
    render(){
        
        return (
            <div className="login">
              <div className="login2">
                  <h1>Ak Blogs Admin Page</h1>
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="floatingInput" value={this.state.username} onChange={this.handleusername} />
                  <label for="floatingInput"> User Name :</label>
                  </div>
                  <div className="form-floating mb-3">
                  <input  type="password" className="form-control" id="floatingPassword" value={this.state.password} onChange={this.handlePassword} />
                  <label for="floatingPassword">Password :</label> 
                  </div>
                  {/* <Link to="/hbd0" style={{textDecoration:"none"}}> */}
                  <button type="button" class="btn btn-outline-primary" onClick={this.handleSubmit}>Submit</button>
                  
                  
                  
                  { this.state.error && 
                      <div class="alert alert-danger" role="alert" >
                      Invalid Credentials — <strong>Try Again</strong>
                      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" ></button>
                    </div>
                      
                    //   <alert severity="error" onClose={this.dismissError}>
                    // <alert>Error</alert>
                    //     
                    // </alert>
                  } 
              
              {/* </Link> */}
              
       
            <div className=""></div>
            
          </div>
            </div>
          );

    }
    
  }
  
  export default Login;