import React, { useContext, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { adddata } from './context/ContextProvider';


const Register = () => {

    const { udata, setUdata } = useContext(adddata);

    const history = useHistory();

    const [inpval, setINP] = useState({
        title: "",
        authorname: "",
        email: "",
        category: "",
        subcategory: "",
        desc: "",
        createDate: new Date().toLocaleString(),
    })

    const setdata = (e) => {
        console.log(e.target.value);
        const { name, value } = e.target;
        setINP((preval) => {
            return {
                ...preval,
                [name]: value
            }
        })
    }


    const addinpdata = async (e) => {
        e.preventDefault();

        const { title,authorname,email,category,subcategory,desc,createDate } = inpval;
        
        // const res = await fetch("https://blog-ak-server.herokuapp.com/register", {
        const res = await fetch("https://akblogs-server.vercel.app/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                title,authorname,email,category,subcategory,desc,createDate
            })
        });

        const data = await res.json();
        console.log(data);

        if (res.status === 422 || !data) {
            console.log("error ");
            alert("Please fill require data or the title is not unique");

        } else {
            history.push("/home")
            setUdata(data)
            console.log("data added");

        }
    }

    
    

    return (
        <div className="container textpage">
            <NavLink to="/home">Home</NavLink>
            <form className="mt-4">
                <div className="row">
                    <div class="mb-3 col-lg-6 col-md-6 col-12">
                        <label for="exampleInputEmail1" class="form-label">Title</label>
                        <input type="text" value={inpval.title} onChange={setdata} name="title" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div class="mb-3 col-lg-6 col-md-6 col-12">
                        <label for="exampleInputPassword1" class="form-label">email</label>
                        <input type="email" value={inpval.email} onChange={setdata} name="email" class="form-control" id="exampleInputPassword1" />
                    </div>
                    <div class="mb-3 col-lg-6 col-md-6 col-12">
                        <label for="exampleInputPassword1" class="form-label">Author</label>
                        <input type="text" value={inpval.authorname} onChange={setdata} name="authorname" class="form-control" id="exampleInputPassword1" />
                    </div>
                    <div class="mb-3 col-lg-6 col-md-6 col-12">
                        <label for="exampleInputPassword1" class="form-label">Category</label>
                        {/* <input type="text" value={inpval.category} onChange={setdata} name="category" class="form-control" id="exampleInputPassword1" /> */}
                        <div><label>
                        <input type="radio" value="Tamil Kavithai" onChange={setdata} name="category"  id="exampleInputPassword1" ></input>
                        Tamil Kavithai</label>
                        </div>
                        <div><label>
                        <input type="radio" value="Tamil Kathai" onChange={setdata} name="category"  id="exampleInputPassword1" ></input>
                        Tamil Kathai</label>
                        </div>
                        <div><label>
                        <input type="radio" value="English Poems" onChange={setdata} name="category"  id="exampleInputPassword1" ></input>
                        English Poems</label>
                        </div>
                        <div><label>
                        <input type="radio" value="English Stories" onChange={setdata} name="category"  id="exampleInputPassword1" ></input>
                        English Stories</label>
                        </div>
                        <div><label>
                        <input type="radio" value="Technical" onChange={setdata} name="category"  id="exampleInputPassword1" ></input>
                        Technical</label>
                        </div>
                        <div><label>
                        <input type="radio" value="Quote" onChange={setdata} name="category"  id="exampleInputPassword1" ></input>
                        Quote</label>
                        </div>
                    </div>
                    <div class="mb-3 col-lg-6 col-md-6 col-12">
                        <label for="exampleInputPassword1" class="form-label">Sub Category</label>
                        <input type="text" value={inpval.subcategory} onChange={setdata} name="subcategory" class="form-control" id="exampleInputPassword1" />
                    </div>
                    <div class="mb-3 col-lg-12 col-md-12 col-12">
                        <label for="exampleInputPassword1" class="form-label">Description</label>
                        <textarea name="desc" value={inpval.desc} onChange={setdata} className="form-control" id="" cols="30" rows="5"></textarea>
                        
                    </div>
                    <div class="mb-3 col-lg-6 col-md-6 col-12">
                        <label for="exampleInputPassword1" class="form-label">CreateDate</label>
                        <input type="" value={inpval.createDate}  name="createDate" class="form-control" id="exampleInputPassword1" />
                    </div>

                    <button type="submit" onClick={addinpdata} class="btn btn-primary">Submit</button>
                </div>
            </form>
        </div>
    )
}
export default Register;
