import Navbaar from './Navbaar';
import Home from './Home';
import Register from './Register';
import Edit from './Edit';
import Details from './Details';
import {Switch,Route} from "react-router-dom"
import Login from './Login';

const Navi = () => {
    return (
        <>
        <Navbaar />
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/edit/:id" component={Edit} />
      <Route exact path="/view/:id" component={Details} />
    </Switch>
        </>
    )
}

export default Navi;