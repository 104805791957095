import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js"

import Navi from './components/Navi';




function App() {
  return (
   <div className="admin-body">
    <Navi />
   
   </div>
  );
}

export default App;






